import React from 'react';
import Box from '@mui/material/Box';

//ori-believe-transparent-opt.png
//ori-believe-transparent-opt-no-alpha.png
//ori-believe-cropped.jpg

const Cover = () => {

  return (
    <Box
      sx={{
        height: { xs: 'auto', md: 1 },
        '& img': {
          objectFit: 'cover',
        },
      }}
    >
      <Box
        component={'img'}
        src={`${process.env.GATSBY_SITE_URL}/images/ori-believe-cropped.jpg`}
        height={{ xs: 'auto', md: 1 }}
        maxHeight={{ xs: 600, md: 1 }}
        width={1}
        maxWidth={1}
      />
    </Box>
  );
};

export default Cover;
