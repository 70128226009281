import React from 'react';
import Box from '@mui/material/Box';
import Typed from 'react-typed';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';

const TypedTitles = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={2}>
        <Typography
          component={'span'}
          variant="h1"
          fontWeight={500}
        >
        Ori Damary<br/>
        </Typography>
        {' '}
          <Typography
            color={'primary'}
            component={'span'}
            variant="h2"
            sx={{
              background: `linear-gradient(180deg, transparent 82%, ${alpha(
                '#ffeb00',
                0.9,
              )} 0%)`,
            }}
          >
            <Typed
              strings={['Engineering Leader', 'Startup Advisor', 'Software Engineer', 'Servant Leader', 'Founder', 'Mentor', 'Ted Lasso Fan']}
              typeSpeed={80}
              loop={true}
            />
          </Typography>
      </Box>
    </Box>
  );
};

export default TypedTitles;
