/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

const FormHeader = () => {
  const theme = useTheme();
  return (
    <Box>
        <Box marginBottom={1}>
          <Typography
            variant="h1"
            align="center"
            sx={{
              color: (theme.palette.common.white),
              [theme.breakpoints.down('sm')]: {
                fontSize: "3rem" 
              },
            }}
          >
            Collaborating
          </Typography>
        </Box>
        <Box marginBottom={{xs: 2, md: 6}}>
          <Typography variant="h5" align="center" color={theme.palette.common.white}>
            I'd love to learn how I can help you. <br />
            Reach out and say hello!
          </Typography>
        </Box>
    </Box>
  );
};

export default FormHeader;
