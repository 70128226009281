/* eslint-disable react/no-unescaped-entities */
import axios from "axios";
import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import validator from 'validator';

const Form = () => {

  const API_URL = process.env.GATSBY_API_URL;
  const theme = useTheme();

  const [formProcessing, setFormProcessing] = useState(false);
  const [formStatusMessage, setFormStatusMessage] = useState(false);
  const [buttonSubmitText, setSubmitButtonText] = useState('Submit');
  const [formFirstName, setFirstName] = useState('');
  const [formLastName, setLastName] = useState('');
  const [formEmail, setEmail] = useState('');
  const [formEmailIsValid, setEmailIsValid] = useState(false);
  const [formEmailErrorMessage, setEmailErrorMessage] = useState(' ');
  const [formMessage, setMessage] = useState('');
  const [formMessageIsValid, setMessageIsValid] = useState(false);
  const [formMessageErrorMessage, setMessageErrorMessage] = useState(' ');

  const setSubmitButtonDefaultText = () => {
    setSubmitButtonText("Submit")
  }

  const setSubmitButtonWaitingText = () => {
    setSubmitButtonText("Stand By...")
  }

  const validateEmail = (e) => {
    setEmail(validator.trim(e.target.value))
    if (validator.isEmail(formEmail)) {
      setEmailIsValid(true)
      setEmailErrorMessage(" ")
    } else {
      setEmailIsValid(false)
      setEmailErrorMessage("Please enter a valid e-mail address.")
    }
  }

  const validateMessage = (e) => {
    setMessage(e.target.value)
    if (formMessage === "") {
      setMessageIsValid(false)
      setMessageErrorMessage("Please enter a message.")
    } else {
      setMessageIsValid(true)
      setMessageErrorMessage(" ")
    }
  }

  const checkIfFormIsValid = () => {
    setFormStatusMessage("")
    setMessage(validator.trim(formMessage))
    return (formMessageIsValid && formEmailIsValid)
  }

  const submitContact = () => {

    setFormStatusMessage("");
    setFormProcessing(true);
    setSubmitButtonWaitingText();

    axios.
      post(API_URL + "contact", {
        firstName: validator.escape(formFirstName),
        lastName: validator.escape(formLastName),
        email: formEmail,
        message: validator.escape(formMessage),
      }, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": window.origin,
        },
      }).
      then((response) => {
        setFormProcessing(false);
        setSubmitButtonDefaultText();
        if(response.status == 200) {
          setFormStatusMessage("Thank you, your message was successfully sent.")
        } else {
          setFormStatusMessage("Uh oh, an error occurred. Please try again or e-mail ori@oridamary.com.")
        }

      }).catch((err) => {
        setFormProcessing(false);
        setSubmitButtonDefaultText();
        setFormStatusMessage("Uh oh, an error occurred. Please try again or e-mail ori@oridamary.com.")
      });
  }

  const submitContactForm = (e) => {
    e.preventDefault();
    if (checkIfFormIsValid()) {
      console.log("Form submitted");
      submitContact();
    } else {
      console.log("Form has errors.");
    }
  }

  return (
    <Box>
      <Box
        padding={{ xs: 3, sm: 6 }}
        width={1}
        component={Card}
        boxShadow={1}
        marginBottom={4}
        bgcolor={theme.palette.primary.light}
      >
        <form method="POST" onSubmit={submitContactForm.bind(this)}>

          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54, maxlength: 5 }}
                label="First name"
                variant="outlined"
                color="primary"
                size="medium"
                name="firstName"
                fullWidth
                value={formFirstName}
                onChange={(e) => setFirstName(e.target.value)}
                inputProps={{ maxLength: 32 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="Last name"
                variant="outlined"
                color="primary"
                size="medium"
                name="lastName"
                fullWidth
                value={formLastName}
                onChange={(e) => setLastName(e.target.value)}
                inputProps={{ maxLength: 32 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ height: 54 }}
                label="Email"
                type="email"
                variant="outlined"
                color="primary"
                size="medium"
                name="email"
                required
                helperText={formEmailErrorMessage}
                fullWidth
                value={formEmail}
                onChange={(e) => validateEmail(e)}
                inputProps={{ maxLength: 128 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Message"
                multiline
                rows={6}
                variant="outlined"
                color="primary"
                size="medium"
                name="message"
                required
                helperText={formMessageErrorMessage}
                fullWidth
                value={formMessage}
                onChange={(e) => validateMessage(e)}
                inputProps={{ maxLength: 1000 }}
              />
            </Grid>
            <Grid item container justifyContent={'center'} xs={12}>
              <Button
                sx={{
                  height: 54,
                  minWidth: 150
                }}
                variant="contained"
                color="secondary"
                size="medium"
                type="submit"
                disabled={formProcessing}
              >
                {buttonSubmitText}
              </Button>
              <p 
                hidden={formStatusMessage == ""}
                >{formStatusMessage}</p>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default Form;
