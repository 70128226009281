/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const ParallaxImageText = (data) => {
  const theme = useTheme();

  return (
    <Box>
      <Typography
        variant="h1"
        align={'center'}
        sx={{
          color: theme.palette.common.white,
          textTransform: 'uppercase',
        }}
      >
        {data.data.headline}
      </Typography>
      <Grid container spacing={{ xs: 4, md: 4 }} >
          <Grid item xs={12} md={2}></Grid>
          <Grid item container xs={12} md={8} alignItems={'center'}>
          <Grid item xs={12} md={2}></Grid>  
          <Typography
        variant="h5"
        component="p"
        color="common.white"
        align={'center'}
        width={'100%'}
      >
       {data.data.paragraph}
      </Typography>
          </Grid>
        </Grid>
    </Box>
  );
};

export default ParallaxImageText;
