import React, { useEffect } from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import Container from 'components/Container';
import {
  Hero,
  ParallaxImageText,
  FormHeader, 
  Form,
} from './components';

const IndexView = () => {
  useEffect(() => {
    const jarallaxInit = async () => {
      const jarallaxElems = document.querySelectorAll('.jarallax');
      if (!jarallaxElems || (jarallaxElems && jarallaxElems.length === 0)) {
        return;
      }

      const { jarallax } = await import('jarallax');
      jarallax(jarallaxElems, { speed: 0.2 });
    };

    jarallaxInit();
  });

  const scrollTo = (id) => {
    setTimeout(() => {
      const element = document.querySelector(`#${id}`);
      if (!element) {
        return;
      }

      window.scrollTo({ left: 0, top: element.offsetTop, behavior: 'smooth' });
    });
  };

  const theme = useTheme();

  const styles = (bgImage, needsFilter) => ({
    position: 'absolute',
    objectFit: 'cover',
    /* support for plugin https://github.com/bfred-it/object-fit-images */
    fontFamily: 'object-fit: cover;',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'left center',
    backgroundImage: `url(${bgImage})`,
    filter: needsFilter ? 'brightness(0.55)' : 'none',
  });
  
// 2364 - signs in bequia
// tom-podmore-TwEhgfCWISA-unsplash.jpg - kew gardens nest
// IMG_0066 - water in croatia
// IMG_3338.jpg - trophies at hackathon
// IMG_8100-2.jpg - OD mariachi
// IMG_9417.jpeg - books at the tate
// green pathway -- 'https://d3o2xo3oi386wy.cloudfront.net/images/landscapes/johannes-plenio-P1Iz3unD6Po-unsplash.jpg'
  const parallaxData = [
    {
      key: "parallax-2",
      headline: "Bringing",
      needsOverlay: true,
      parallaxImage: `${process.env.GATSBY_SITE_URL}/images/landscapes/IMG_9417.jpeg`,
      paragraph: "nearly 20 years of experience in tech -- as a founder & Head of Engineering, a Software Engineer, an Engineering Manager, and as Chief of Staff to the CTO of a Fortune 500 company."
    },
      {
        key: "parallax-1",
        headline: "Building",
        needsOverlay: true,
        overlay: `linear-gradient(rgb(255,196,2, .5), rgb(255,196,2, .5))`,
        parallaxImage: `${process.env.GATSBY_SITE_URL}/images/landscapes/IMG_3338.jpg`,
        paragraph: "world-class products, teams, and organizations. I've led small teams and large teams, through hypergrowth and hiring freezes, from Day 1 to IPO."
      },
      {
        key: "parallax-0",
        headline: "Navigating",
        needsOverlay: false,
        parallaxImage: `${process.env.GATSBY_SITE_URL}/images/landscapes/IMG_0066.jpg`,
        paragraph: "the never-ending challenges that come with delivering software, maintaining operational excellence, and fostering a healthy culture."
      },
      {
        key: "parallax-3",
        headline: "Enjoying",
        needsOverlay: true,
        parallaxImage: `${process.env.GATSBY_SITE_URL}/images/landscapes/IMG_8100-2.jpg`,
        paragraph: "(almost) every step of the way."
      }
    ];

  return (
    <Main>
      <Hero />
      {parallaxData.map(function(element){
          return<Box
          className={'jarallax'}
          data-jarallax
          data-speed="0.2"
          position={'relative'}
          minHeight={'100vh'}
          display={'flex'}
          alignItems={'center'}
          key={element.key}
        >
          <Box
            className={'jarallax-img'}
            sx={styles(element.parallaxImage, element.needsOverlay)}
          />
            <Container>
              <ParallaxImageText data={element} />
            </Container>
        </Box>;
      })}
      <Box
        id="contact-form"
        minHeight={'100vh'}
        display={'flex'}
        alignItems={'center'}
        bgcolor={'alternate.main'}
        sx={{
          backgroundColor:(theme.palette.color2.main),
          backgroundImage: `linear-gradient(rgba(2,83,159, .6), rgba(2,83,159, .6)), url(${process.env.GATSBY_SITE_URL}/images/landscapes/IMG_5914-cropped.jpeg);`,
          backgroundSize: '120%',
          backgroundPosition: 0,
        }}
      >
        <Container>
        <FormHeader />
        <Grid container spacing={{ xs: 4, md: 8 }}>
          <Grid item xs={12} md={3}></Grid>
          <Grid item container xs={12} md={6} alignItems={'center'}>
            <Form />
          </Grid>
          <Grid item xs={12} md={3}></Grid>
        </Grid>
      </Container>
      </Box>
    </Main>
  );
};

export default IndexView;